/* stylelint-disable */

.spz-homepage{
    @media (max-width:991px) {
    margin-right: -32px;
    }
    @media (max-width:767px) {
    margin-right: auto;
    }
  }
    // &.spz-analytics {
    //   .spz-email-wrapper {
    //     max-width: 523px;
    //     @media (max-width:767px) {
    //       max-width: 100%;
    //     }
    //     .form-inner button {
    //       max-width: 164px;
    //       @media (max-width:767px) {
    //         max-width: 100%;
    //       }
    //     }
    //   }
    // }
  
  
    .spz-email-wrapper {
      max-width: 485px;
      width: 100%;
      margin-top: 40px;
  
      @media (max-width:767px) {
        margin: 10px auto 0;
        max-width: 100%;
      }
  
      form {
        @media (max-width:767px) {
          position: relative;
        }
      }
  
      .form-inner {
        align-items: center;
        display: flex;
        border-radius: 32px;
        box-shadow: 0 0 0 8px rgba(125, 95, 255, 0.20);
        backdrop-filter: blur(12px);
        justify-content: space-between;
        +#customerrormessage{
            opacity: 0;
        }
        @media (max-width:767px) {
          flex-direction: column;
          box-shadow: none;
        }
  
        &.isError {
          .floating-label {
            @media (max-width:767px) {
              margin-bottom: 28px;
            }
  
            .floating-input {
              color: #D42055;
  
              &::-webkit-input-placeholder {
                color: #D42055;
                opacity: 1;
              }
  
              &:-moz-placeholder {
                /* Firefox 18- */
                color: #D42055;
                opacity: 1;
              }
  
              &::-moz-placeholder {
                /* Firefox 19+ */
                color: #D42055;
                opacity: 1;
              }
  
              &:-ms-input-placeholder {
                /* Microsoft Edge */
                color: #D42055;
                opacity: 1;
              }
            }
          }
          +#customerrormessage{
            opacity: 1;
          }
        }
  
  
  
        &.focused {
          box-shadow: 0 0 0 10px rgba(125, 95, 255, .32);
  
          @media (max-width:767px) {
            box-shadow: none;
          }
        }
  
        .floating-label {
          max-width: calc(100% - 126px);
          position: relative;
          width: 100%;
  
          @media (max-width:767px) {
            backdrop-filter: blur(2px);
            border-radius: 32px;
            box-shadow: 0 0 0 8px rgba(125,95,255,.2);
            margin: 14px 0;
            max-width: 100%;
          }
  
          .floating-input {
            background: #fff;
            border: 0;
            border-radius: 32px 0 0 32px;
            box-shadow: none;
            color: #1e1a34;
            height: 40px;
            outline: none;
            outline-offset: unset;
            padding: 12px 16px;
            width: 100%;
            font-family: 'EuclidCircularA';
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
  
            @media (max-width:767px) {
              border-radius: 32px;

            }
  
            &::placeholder {
              color: #76777B;
              opacity: 1;
            }
  
            /* Vendor prefixes for older browsers */
            &::-webkit-input-placeholder {
              color: #76777B;
              opacity: 1;
            }
  
            &:-moz-placeholder {
              /* Firefox 18- */
              color: #76777B;
              opacity: 1;
            }
  
            &::-moz-placeholder {
              /* Firefox 19+ */
              color: #76777B;
              opacity: 1;
            }
  
            &:-ms-input-placeholder {
              /* Microsoft Edge */
              color: #76777B;
              opacity: 1;
            }
  
            &:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px white inset !important;
              /* Change the color to whatever you want */
              -webkit-text-fill-color: inherit !important;
            }
          }
  
          label {
            color: #2F2B43;
            font-family: 'FOUNDERS GROTESK';
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            opacity: 0.64;
            left: 16px;
            pointer-events: none;
            position: absolute;
            top: 16px;
            transition: all .2s ease;
            -moz-transition: all .2s ease;
            -webkit-transition: all .2s ease;
          }
  
                                   
        }
  
        button {
          background: #7d5fff;
          border-radius: 0 32px 32px 0;
          height: 40px;
          outline: none;
          padding: 7px 20px;
          width: 100%;
          max-width: 126px;
  
          @media (max-width:767px) {
            border-radius: 32px;
            margin: 14px 0;
            max-width: 100%;
            box-shadow: 0 0 0 8px rgba(125, 95, 255, .2);
          }
  
          &:hover,
          &:active {
            background: #4A25AA;
          }
  
          span {
            color: #fff;
            font-family: EuclidCircularA;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
            text-transform: capitalize;
          }
        }
  
      }
  
      #customerrormessage {
        color: #D42055;
        font-family: 'EuclidCircularA';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px 0 0 15px;
  
        @media (max-width:767px) {
          margin: 10px auto 0px;
          text-align: center;
          position: absolute;
          top: 55px;
          left: 0;
          right: 0;
        }
      }
  
      .product-text {
        color: #FFF;
        font-family: 'RED HAT TEXT';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 25px;
  
        @media (max-width:767px) {
          margin-top: 19px;
          text-align: center;
        }
  
        a {
          color: #fff;
          text-decoration: underline;
          text-underline-position: under;
  
          &:hover {
            color: #B4C3F0;
          }
        }
      }
    }
