.testimonials {
    @apply relative  overflow-hidden;
    &--lg {
        @apply bg-gray-19 py-16 lg:pt-32 pb-16;
        .slide {
            &--left {
                @apply col-span-5 mb-12 lg:pr-8 md:mb-0;
            }
            &--right {
                @apply col-span-7;
            }
            &--subtitle {
                @apply mb-4 font-medium md:mb-6 text-purple label;
            }
        }
    }
    .slide {
        @apply md:grid md:grid-cols-12 gap-8;
        &--testimonial {
            @apply mb-4 md:mb-6 h4;
        }
        &--tname {
            @apply lead opacity-70;
        }
    }
    .tpagination {
        @apply grid-cols-12 gap-8 md:grid;
        &--left {
            @apply col-span-5;
        }
        &--right {
            @apply col-span-7;
        }
    }

    &--sm {
        @apply py-6 lg:pt-12 lg:max-w-[initial] max-w-xs;
        .slide {
            @apply max-w-[calc(100vw-54px)] lg:max-w-full;
            &--left {
                @apply hidden;
            }
            &--right {
                @apply col-span-full pr-4;
            }
            &--subtitle {
                @apply mb-4 font-medium md:mb-6 text-purple text-12 uppercase;
            }
            &--testimonial {
                @apply text-20 leading-26;
            }
            &--tname {
                @apply text-12 leading-16;
            }
        }
        .tpagination {
            @apply hidden;
        }
    }
}
