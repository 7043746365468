@font-face {
    font-family: 'EuclidCircularA';
    src: url('../public/fonts/EuclidCircularA-Light.eot');
    src: url('../public/fonts/EuclidCircularA-Light.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/EuclidCircularA-Light.woff2') format('woff2'),
        url('../public/fonts/EuclidCircularA-Light.woff') format('woff'),
        url('../public/fonts/EuclidCircularA-Light.ttf') format('truetype'),
        url('../public/fonts/EuclidCircularA-Light.svg#FontName') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../public/fonts/EuclidCircularA-Regular.eot');
    src: url('../public/fonts/EuclidCircularA-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/EuclidCircularA-Regular.woff2') format('woff2'),
        url('../public/fonts/EuclidCircularA-Regular.woff') format('woff'),
        url('../public/fonts/EuclidCircularA-Regular.ttf') format('truetype'),
        url('../public/fonts/EuclidCircularA-Regular.svg#FontName')
            format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../public/fonts/EuclidCircularA-Medium.eot');
    src: url('../public/fonts/EuclidCircularA-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/EuclidCircularA-Medium.woff2') format('woff2'),
        url('../public/fonts/EuclidCircularA-Medium.woff') format('woff'),
        url('../public/fonts/EuclidCircularA-Medium.ttf') format('truetype'),
        url('../public/fonts/EuclidCircularA-Medium.svg#FontName') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../public/fonts/EuclidCircularA-Semibold.eot');
    src: url('../public/fonts/EuclidCircularA-Semibold.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/EuclidCircularA-Semibold.woff2') format('woff2'),
        url('../public/fonts/EuclidCircularA-Semibold.woff') format('woff'),
        url('../public/fonts/EuclidCircularA-Semibold.ttf') format('truetype'),
        url('../public/fonts/EuclidCircularA-Semibold.svg#FontName')
            format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../public/fonts/EuclidCircularA-Bold.eot');
    src: url('../public/fonts/EuclidCircularA-Bold.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/EuclidCircularA-Bold.woff2') format('woff2'),
        url('../public/fonts/EuclidCircularA-Bold.woff') format('woff'),
        url('../public/fonts/EuclidCircularA-Bold.ttf') format('truetype'),
        url('../public/fonts/EuclidCircularA-Bold.svg#FontName') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'enfusion-icons';
    src: url('../public/icons/enfusion-icons.eot?33jlb9');
    src: url('../public/icons/enfusion-icons.eot?33jlb9#iefix')
            format('embedded-opentype'),
        url('../public/icons/enfusion-icons.ttf?33jlb9') format('truetype'),
        url('../public/icons/enfusion-icons.woff?33jlb9') format('woff'),
        url('../public/icons/enfusion-icons.svg?33jlb9#enfusion-icons')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
