// Custom pageination active state
.swiper-container {
    &.swiper-light {
        +.sticky-mobile .swiper-custom-pagination {
            .swiper-pagination-bullet {
                background: #1e1a34 !important;
            }
        }
    }
}
.insight-custom-pagination,
.employees-custom-pagination,
.testimonials-custom-pagination {
    .swiper-pagination-bullet {
        background: #1e1a34 !important;
    }
}
// Slider custom pagination
.swiper-custom-pagination,
.insight-custom-pagination,
.employees-custom-pagination,
.testimonials-custom-pagination {
    width: 50%;
    text-align: left;
    padding-left: 24px;
    bottom: 30px !important;
    .swiper-pagination-bullet {
        width: 4px;
        height: 4px;
        background: white !important;
        transition: 0.3s;
        margin: 0 16px !important;
        border-radius: 8px;
        opacity: 1;
        position: relative;
        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            transform: scale(5);
        }
        &.swiper-pagination-bullet-active {
            transform: scale(2);
            &:before {
                transform: scale(2);
            }
        }
    }
}
@media screen and (max-width: 768px) {

    .testimonials-custom-pagination .swiper-pagination-bullet {
        height: 8px;
        width: 8px;
    }

    div.testimonials-custom-pagination {
        display: flex;
        align-items: center;
        gap: 25px;
        bottom: -35px;
    }
}
.insight-custom-pagination,
.employees-custom-pagination,
.testimonials-custom-pagination {
    width: 100%;
    text-align: center;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -1px;
    position: relative;

    .swiper-pagination-bullet {
        background: #1e1a34 !important;
        display: block;
        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            transform: scale(5);
        }
    }
}
@media only screen and (max-width: 1024px) {
    .sticky-mobile {
        &:before,
        &:after {
            opacity: 0;
            transition: 0.3s;
            height: 176px;
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0%;
            z-index: -1;
        }
        &:after {
            background-image: linear-gradient(360deg,
                    #f8f8f7 0%,
                    rgba(248, 248, 247, 0) 100%),
                linear-gradient(360deg, #f8f8f7 0%, rgba(248, 248, 247, 0) 100%),
                linear-gradient(360deg, #f8f8f7 0%, rgba(248, 248, 247, 0) 100%);
        }
        &:before {
            background-image: linear-gradient(360deg,
                    #101820 0%,
                    rgba(248, 248, 247, 0) 100%),
                linear-gradient(360deg, #101820 0%, rgba(248, 248, 247, 0) 100%),
                linear-gradient(360deg, #101820 0%, rgba(248, 248, 247, 0) 100%);
        }
    }

    .swiper-light {
        +.sticky-mobile {
            &:after {
                opacity: 1;
            }
        }
    }
    :not(.swiper-light) {
        +.sticky-mobile {
            &:before {
                opacity: 1;
            }
        }
    }
    .swiper-custom-pagination,
    .insight-custom-pagination,
    .employees-custom-pagination,
    .testimonials-custom-pagination {
        .swiper-pagination-bullet {
            margin: 0 12px !important;
        }
    }
}
@media screen and (max-width: 480px) {
    .swiper-custom-pagination,
    .insight-custom-pagination,
    .employees-custom-pagination,
    .testimonials-custom-pagination {
        .swiper-pagination-bullet {
            margin: 0 6px !important;
        }
    }
}

// Animation
.swiper-slide-active {
    .is-animated {
        animation-name: fadeInUp;
        animation-duration: 1s;
    }
}
.swiper-auto-width {
    .swiper-slide {
        width: auto !important;
    }
}
.full-screen-right {
    width: 100vw;
    max-width: calc(100vw - ((100vw - 1104px) / 2));

    @media only screen and (max-width: 1150px) {
        max-width: calc(100vw - ((100vw - 980px) / 2));
    }
    @media only screen and (max-width: 1024px) {
        max-width: calc(100vw - 24px);
    }
}

.swiper-button-disabled {
    opacity: 0.4;
}

