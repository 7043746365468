.icon-expaded {
    .absolute {
        left: -1px;
        transition: 0.3s;
    }
    .relative {
        right: -11px;
        transition: 0.3s;
    }
}

.icon-collapse {
    .absolute {
        left: 10px;
        transition: 0.3s;
    }
    .relative {
        right: 0px;
        transition: 0.3s;
    }
}
