@layer base {
    .btn {
        @apply text-15 leading-24 py-2 px-5 rounded-20 inline-block tracking-20 transition-all text-center;

        /* button primary */
        &--primary {
            @apply text-white bg-purple;
            @apply hover:bg-purple-medium;
        }
        &--midnight {
            @apply text-white bg-purple-midnight;
            @apply hover:bg-purple-dark;
        }
        &--periwinkle {
            @apply text-purple-midnight bg-purple-periwinkle;
            @apply hover:bg-purple-light;
        }
        &--white {
            @apply text-purple-midnight bg-white;
            @apply hover:bg-gray-17;
        }
        &--light {
            @apply text-purple-midnight bg-gray-18 text-opacity-50;
            @apply hover:bg-gray-16 hover:text-opacity-100;
            &--on {
                @apply text-white bg-purple-midnight;
                @apply hover:bg-purple-midnight hover:text-white;
            }
        }

        /* button outline */
        &--o-primary {
            @apply leading-20 border-2;
            @apply text-purple border-purple;
            @apply hover:border-purple-medium hover:text-purple-medium;
            &.btn--lg {
                @apply py-3;
            }
        }
        &--o-midnight {
            @apply leading-20 border-2;
            @apply text-purple-midnight border-purple-midnight;
            @apply hover:text-purple-medium hover:border-purple-medium;
            &.btn--lg {
                @apply py-3;
            }
        }
        &--o-periwinkle {
            @apply leading-20 border-2;
            @apply text-purple-periwinkle border-purple-periwinkle;
            @apply hover:text-purple-light hover:border-purple-light;
            &.btn--lg {
                @apply py-3;
            }
        }
        &--o-white {
            @apply leading-20 border-2;
            @apply text-white border-white;
            @apply hover:text-gray-16 hover:border-gray-16;
            &.btn--lg {
                @apply py-3;
            }
        }

        /* button large */
        &--lg {
            @apply py-3.5 px-8 text-18 leading-28 rounded-30;
        }
    }
}

//Circle icon of CTA link
.icon-circle {
    padding-right: 13px;
    &__circle {
        transform: rotate(-180deg);
    }
    &__base {
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
        stroke: #7d5fff;
    }
    &__fill {
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
        stroke: white;
        stroke-dasharray: 100;
        animation: drawout 1s forwards;
        &--dark {
            stroke: rgb(30, 26, 52);
        }
    }
    &:hover {
        .icon-circle__fill {
            animation: draw 1s forwards;
        }
    }
}
@keyframes draw {
    from {
        stroke-dashoffset: 100;
    }
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes drawout {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 100;
    }
}
