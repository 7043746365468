/* patterns */
.bg-pattern {
    @for $i from 1 through 4 {
        &-#{$i} {
            background-image: url('../public/images/patterns/bg-dot-pattern-#{$i}.svg');
        }
    }
}
/* backgrounds */
.bg {
    @for $i from 1 through 14 {
        &-gradient-#{$i} {
            background-image: url('../public/images/gradients/gradient-#{$i}.jpg');
            background-size: cover;
        }
    }
}
.bg-gradient-12-flip {
    background-image: url('../public/images/gradients/gradient-12-flip.jpg');
    background-size: cover;
}
.bg-pattern-2-midnight {
    background-image: url('../public/images/patterns/bg-dot-pattern-2-midnight.svg');
}
.bg-pattern-2-periwinkle {
    background-image: url('../public/images/patterns/bg-dot-pattern-2-periwinkle.svg');
}
