.card {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 100%;
}

.card-image {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
}

.card-image img,
.card-image video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card-body-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.card-body-content img {
    width: 100px;
    height: auto;
}

.client-swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.client-swiper-slide {
    width: 350px;
    padding: 2rem 1rem 1rem 0.2rem;
}
@media screen and (max-width: 768px) {
    .client-swiper-slide {
        width: 100%;
        padding: 1rem 1.2rem 1rem 0rem;
    }
}

.podcastname {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 2.5px;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 3rem;
}
.podcastexcerpt {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}


.podcastheading {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 2.5px;
    text-align: left;
}

.carouselheading {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-align: left;
    padding-top: 1rem;
}
select option.dropdown-title {
    font-size: 22px !important;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}
// select#category-select {
//                        appearance: none;
//                        background: #ffffff;
//                        border: 1px solid #cccccc;
//                        border-radius: 4px;
//                        font-size: 18px;
//                        padding: 8px 12px;
//                    }

//    select#category-select option {
//        font-size: 16px;
//        color: #333333;
//    }

// .featured-video > div {
//     height: 50% !important;
// }
div.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    display: flex;
    align-items: center;
    bottom: 80px;
    left: 16px;
}

div.featured-pagination {
    display: flex;
    align-items: center;
    gap: 9px;
    position: absolute;
    bottom: 3px;
    z-index: 10;
}

@media screen and (max-width: 768px) {
    div.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
        bottom: 45px;
        left: 40%;
        gap: 25px;
    }
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active,
div.featured-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #000;
    transform: scale(2, 2);
}

.podcast-carousel-bullets
    > div.swiper-pagination-bullets
    > span.swiper-pagination-bullet,
div.featured-pagination span.swiper-pagination-bullet {
    aspect-ratio: 1/1;
    height: unset;
    width: 4px;
}

@media screen and (max-width: 768px) {
    .podcast-carousel-bullets
        > div.swiper-pagination-bullets
        > span.swiper-pagination-bullet,
    div.featured-pagination span.swiper-pagination-bullet {
        aspect-ratio: 1/1;
        height: unset;
        width: 8px;
    }

    div.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
        left: unset;
        display: flex;
        align-items: center;
        gap: 25px;
    }

    div.featured-pagination {
        display: flex;
        align-items: center;
        gap: 25px;
        bottom: -35px;
    }
}
.client-stories-carousel {
    margin-bottom: -64px;
}

.client-stories-carousel>div.swiper-wrapper {
    padding-bottom: 94px;
}

@media screen and (max-width: 768px) {
    .swiper-tracker {
        display: none;
    }
}

.swiper-tracker {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    // bottom: 141px;
}

.swiper-tracker-position {
    bottom: 72px;
}

div.swiper-wrapper .swiper-slide.swiper-slide-duplicate.swiper-slide-next.swiper-slide-duplicate-prev,
div.swiper-wrapper .swiper-slide.swiper-slide-duplicate.swiper-slide-active.swiper-slide-duplicate-next {
    width: 1125px !important;
}

@media (min-width: 1440px) {
    div p.swiper-tracker.custom-2xl {
        right: 49.8%;
    }
}
