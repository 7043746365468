$accentColors: (
  "purple": theme('colors.purple.DEFAULT'),
  "blue": theme('colors.blue'),
  "yellow": theme('colors.yellow'),
  "teal": theme('colors.teal.DEFAULT')
);

.contact-form-hero {
  h1 {
    b {
      font-weight: inherit;
    }
  }
  &__description {
    ul {
      margin: 2rem 0;
    }
    li {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: '';
        width: 19px;
        height: 19px;
        margin-right: 0.75rem;
        mask-image: url('data:image/svg+xml,%3Csvg%20width%3D%2219%22%20height%3D%2219%22%20viewBox%3D%220%200%2019%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9.5%2019C4.28857%2019%200%2014.7114%200%209.5C0%204.28857%204.28857%200%209.5%200C11.7257%200%2013.9514%200.814286%2015.6343%202.22571L13.5171%203.96286C12.2686%202.93143%2011.0743%202.66%209.5%202.66C5.75429%202.66%202.66%205.7%202.66%209.5C2.66%2013.3%205.7%2016.34%209.5%2016.34C13.3%2016.34%2016.34%2013.3%2016.34%209.5C16.34%209.44571%2016.34%209.33714%2016.34%209.28286L18.7829%207.38286C18.8914%207.98%2019%208.90286%2019%209.5C19%2014.7114%2014.7114%2019%209.5%2019Z%22%20fill%3D%22currentColor%22%2F%3E%3Cpath%20d%3D%22M16.5017%203.63745L9.60744%208.90316L5.91602%206.40602V9.60888C5.91602%209.60888%209.66173%2012.2689%209.66173%2012.1603C9.66173%2012.0517%2018.1846%205.53745%2018.1846%205.53745L16.5017%203.63745Z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E');
        mask-size: cover;
        mask-repeat: no-repeat;
      }
    }
  }
  @each $accent, $color in $accentColors {
    &[data-accent="#{$accent}"] {
      h1 b,
      .contact-form-hero__eyebrow {
        color: $color;
      }
      .contact-form-hero__description {
        a {
          color: $color;
        }
        ul li:before {
          background-color: $color;
        }
      }
    }
  }
}

.testimonial-card {
  &__quote {
    text-indent: -10px;
  }
}