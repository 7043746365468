.investment-strategy{
    flex-basis: 50%;
}

@media screen and (min-width: 768px){
    .investment-strategy{
        flex-basis: 33.333334%;
    }
}

@media screen and (min-width: 1440px) {
    .investment-strategy{
        flex-basis: 16.666667%;
    }  
}