.scrollbar-thin {
    &::-webkit-scrollbar {
        width: 4px;
        height: 120px;
        margin-right: 10px;
    }

    &::-webkit-scrollbar-button {
        background: white;
    }

    &::-webkit-scrollbar-track-piece {
        background: white;
    }

    &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
    }
}

.two-col-partners-numbers {
    @apply w-full;

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal>.swiper-pagination-bullets {
        left: 0;
        width: fit-content;
    }

    .swiper-pagination-bullet {
        width: 4px;
        height: 4px;
        background: #1e1a34;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        transform: scale(2);
    }

    @apply bg-gray-19;

    &__title {
        @apply text-purple font-medium container;
        font-size: 30px;

        @screen lg {
            font-size: 48px;
            font-weight: 400;
            line-height: 56px;
            letter-spacing: -0.5px;
        }
    }

    &__inner {
        @apply flex flex-col-reverse;
    }

    &__left {
        @apply bg-gray-19 md:pt-20 pt-12 pb-8;
    }

    &__slide {
        @apply flex-row flex-wrap sm:justify-start justify-between flex xl:gap-x-16 sm:gap-x-20 gap-x-8 lg:gap-y-[4.8125rem] gap-y-[4.1875rem] md:pt-16 md:pb-24 pt-8 pb-16 w-full mx-auto;

        figure {
            @apply w-32;
        }
    }

    &__logos {
        @apply relative w-full mr-auto pr-4 pl-[22px] ml-[-5px] sm:container;
    }

    &__logo {
        @apply w-[7.5rem] h-auto;
    }

    &__left,
    &__right {
        @apply px-4 sm:px-0;
    }

    &__right {
        @apply text-white bg-no-repeat bg-cover py-28;
        background-image: url("../public/images/partners_background.png");
    }

    &__grid {
        @apply flex-row flex-wrap flex justify-between gap-y-20 gap-x-[3.5rem] md:gap-x-10 lg:gap-x-[7.5rem] pt-20 container;

        &-item {
            @apply lg:w-1/5 w-5/12 flex-1;

            &-number {
                @apply leading-[88px] md:text-[76px] text-[56px] font-light;
            }

            &-title {
                @apply text-purple-periwinkle border-t pt-2 border-solid text-12 tracking-250;
                font-weight: 500;
                border-color: rgba(255, 255, 255, 0.3);
            }
        }
    }

    &__eyebrow {
        @apply container text-purple-periwinkle uppercase font-medium text-14 tracking-250 leading-22;
        font-weight: 500;
        margin-bottom: 13px;
    }
}

.partners__category {
    &-mobile {
        @apply relative;

        &[open] summary::after {
            content: url("../public/icons/dropdown-up-arrow.png");
        }

        summary {
            list-style: none;

            &::after {
                @apply absolute right-0 font-semibold;
                content: url("../public/icons/dropdown-arrow.png");
                color: #7d5fff;
            }
        }

        summary::-webkit-details-marker {
            display: none;
        }
    }
}

.two-col-partners-numbers__right>.two-col-partners-numbers__title {
    @apply text-white;
}

.two-col-partners-numbers__left>.two-col-partners-numbers__title {
    @apply pb-12 sm:pb-0;
}